<template>
    <div v-if="$store.state.highlight.editHighlight === undefined">
        <div v-if="openTools" class="annotation-toolbar flex">
            <div class="bg-scorelitgray bg-opacity-75 flex justify-center items-center rounded border border-gray-900 shadow">
                <i class="p-3 border-r border-border-gray text-gray-200 fal fa-undo hover:bg-gray-800 hover:text-white transition duration-200 cursor-pointer" v-on:click="startDrawing('backward')"></i>
                <i class="p-3 text-gray-200 fal fa-redo hover:bg-gray-800 hover:text-white transition duration-200 cursor-pointer" v-on:click="startDrawing('forward')"></i>
            </div>
        <div class="flex justify-center text-center border border-gray-900 shadow rounded ml-5">
            <select v-model="drawingColor" v-on:change="setColorDrawing" class="block appearance-none bg-scorelitgray bg-opacity-75 text-gray-200 hover:bg-gray-800 hover:text-white px-3 cursor-pointer rounded-none" style="font-size: 0.8rem">
                <option value="red"><i18n-t keypath="review.drawing.color.red" /></option>
                <option value="green"><i18n-t keypath="review.drawing.color.green" /></option>
                <option value="white"><i18n-t keypath="review.drawing.color.white" /></option>
                <option value="blue"><i18n-t keypath="review.drawing.color.blue" /></option>
            </select>

            <i class="p-3 border-r border-l border-border-gray fal fa-arrow-up hover:bg-gray-900 hover:text-white transition duration-200 cursor-pointer" v-bind:class="drawArrowActive ? 'bg-gray-900 text-white' : 'bg-scorelitgray bg-opacity-75  text-gray-200'" v-on:click="startDrawing('arrow')"></i>
            <i class="p-3 border-r border-border-gray far fa-square hover:bg-gray-900 hover:text-white transition duration-200 cursor-pointer" v-bind:class="drawRectActive ? 'bg-gray-900 text-white' : 'bg-scorelitgray bg-opacity-75 text-gray-200'" v-on:click="startDrawing('rect')"></i>
            <i class="p-3 border-r border-border-gray text-gray-300 far fa-slash hover:bg-gray-900 hover:text-white transition duration-200 cursor-pointer" v-bind:class="drawLineActive ? 'bg-gray-900 text-white' : 'bg-scorelitgray bg-opacity-75 text-gray-200'" v-on:click="startDrawing('line')"></i>
            <i class="dottedLine p-3 hover:bg-gray-900 transition duration-200 cursor-pointer" v-bind:class="drawDottedLineActive ? 'bg-gray-900 text-white' : 'bg-scorelitgray bg-opacity-75 text-gray-200'" v-on:click="startDrawing('dottedLine')">
                <svg class=" w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640.32 512.16">
                    <path class="dottedLine" fill="currentColor" d="M678.4,284.48c4.45.9,8,3.49,11.46,6.2q42.93,33.3,86,66.49c.63.48,1.23,1,2.06,1.67-13,16.86-26,33.66-39.09,50.59-1.92-1.47-3.69-2.81-5.45-4.17Q691.12,372.62,648.87,340c-3.67-2.82-6.62-6-7.65-10.64a4.2,4.2,0,0,0-.74-1.19v-4.32a6.17,6.17,0,0,0,.76-1.46,19.32,19.32,0,0,1,4.1-7.76q9.15-11.7,18.23-23.45A16.57,16.57,0,0,1,673,285c.53-.13,1-.34,1.57-.51Z" transform="translate(-640.48 -284.48)"/>
                    <path class="dottedLine" fill="currentColor" d="M1241.92,796.64c-4.28-1.34-7.65-4.14-11.12-6.83q-42-32.53-84.09-65l-2.11-1.69,39.06-50.54c.83.59,1.49,1,2.11,1.51q44.06,34,88.1,68.08a16.63,16.63,0,0,1,6,7.86c.38,1.12.6,2.29.89,3.43v4.32c-.12.2-.34.39-.35.59a12.94,12.94,0,0,1-3.08,7.14Q1267.71,778,1258,790.43a16.38,16.38,0,0,1-8.12,5.65,10.64,10.64,0,0,0-1.25.56Z" transform="translate(-640.48 -284.48)"/>
                    <path class="dottedLine" fill="currentColor" d="M1013.45,540.86l104.41,80.71Q1098.3,646.89,1079,671.9c-1.7-.66-101.35-77.45-104.54-80.54Z" transform="translate(-640.48 -284.48)"/>
                    <path class="dottedLine" fill="currentColor" d="M843.77,409.7,947.59,490l-39.14,50.66L804.63,460.36Z" transform="translate(-640.48 -284.48)"/>
                </svg>
            </i>
        </div>
        <div class="bg-scorelitgray bg-opacity-75 flex rounded ml-5 border border-gray-900 shadow">
            <i class="self-center p-3 border-r border-border-gray text-gray-300 fal fa-times hover:bg-red-700 hover:text-white fa-lg over:text-white transition duration-200 cursor-pointer" v-on:click="cancelDrawing()"></i>
            <i class="self-center p-3 text-gray-300 fal fa-check hover:bg-green-700 hover:text-white transition duration-200 cursor-pointer" v-on:click="saveDrawing()"><span class="ml-2 text-sm font-semibold"><i18n-t keypath="review.done" /></span></i>
        </div>
    </div>
    <div v-else>
        <div class="annotation-button">
            <button class="text-gray-200 bg-black-500 rounded px-4 py-3 text-sm font-medium bg-scorelitgray hover:bg-gray-800 hover:text-white transition duration-200" v-on:click="openToolsEmit"><i class="fal fa-vector-square mr-3"></i>
                <i18n-t keypath="review.drawing.button" />
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "VideoDrawingControls",
    data() {
        return {
            drawingColor: 'red',
            openTools: false,
            drawNumberActive: false,
            drawArrowActive: false,
            drawRectActive: false,
            drawLineActive: false,
            drawDottedLineActive: false,
        }
    },
    methods: {
        openToolsEmit() {
            this.$emit("open-tools");
            this.openTools = true
        },
        cancelDrawing() {
            this.$emit("cancel-drawing");
            this.openTools = false;
        },
        saveDrawing() {
            this.$emit("save-drawing");
            this.openTools = false;
        },
        startDrawing(type) {
            this.$emit("start-drawing", type);
            this.drawNumberActive = false; 
            this.drawArrowActive = false;
            this.drawRectActive = false;
            this.drawLineActive = false;
            this.drawDottedLineActive = false;
            if(type === 'arrow') {
                this.drawArrowActive = true;
            }
            if(type === 'rect') {
                this.drawRectActive = true;
            }
            if(type === 'line') {
                this.drawLineActive = true;
            }
            if(type === 'dottedLine') {
                this.drawDottedLineActive = true;
            }
            if(type === 'number') {
                this.drawNumberActive = true;
            }
        },
        setColorDrawing() {
            this.$emit("change-color-drawing", this.drawingColor,);
        },
    }
}
</script>

<style scoped>
    .annotation-button {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: justify;
        justify-content: space-between;
        right: 1.5rem;
        position: absolute;
        top: 1.5rem;
        z-index:3
    }

    .annotation-toolbar {
        right: 1.5rem;
        position: absolute;
        top: 1.5rem;
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        z-index:3;
    }

    .dottedLine svg {
        width: 20px;
        height: 16.8px;
    }

    .dottedLine:hover svg path {
        fill: white;
        transition-property: fill, transform;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 200ms;
    }
</style>