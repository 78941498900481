<template>
  <div ref="canvasHolder">
    <canvas id="myCanvas" ref="canvas" class="w-full rounded relative"
            v-on:mousemove="draw($event)"
            v-on:mousedown="beginDrawing"
            v-on:mouseup="stopDrawing"
    ></canvas>
  </div>
</template>

<script>

export default {
  name: "VideoDrawingCanvas",
  props: ['type', 'backgroundImage', 'color'],
  data() {
    return {
      canvas: null,
      x: 0,
      y: 0,
      isDrawing: false,
      mouseDownX: 0,
      mouseDownY: 0,
      lines: [],
      historyLines: [],
      penWidth: 2.5,
      img: new Image()
    }
  },
  mounted() {

    let c = document.getElementById("myCanvas");
    this.$refs.canvas.height = this.$refs.canvasHolder.scrollHeight + 78;
    this.$refs.canvas.width = this.$refs.canvasHolder.scrollWidth;
    this.canvas = c.getContext('2d');
    this.img.crossOrigin = " ";
  },
  watch: {
    backgroundImage(newVal) {
      // console.log("watch", 'backgroundImage', newVal )
      let img1 = new Image();
      img1.crossOrigin = " ";
      img1.src = newVal;
      this.img.src = newVal;
      img1.onload = () => {
        this.setBackgroundImage(img1);
      }
    },
    type(newVal) {
      switch (newVal) {
        case "forward":
          if (this.historyLines.length > 0) {
            this.lines.push(this.historyLines.pop())
          }
          break;
        case "backward":
          if (this.lines.length > 0) {
            this.historyLines.push(this.lines.pop())
          }
          break;
      }
      this.saveCanvas();
      this.clearCanvas();
    },
    '$store.state.highlight.removeDrawing': function() {
      this.$store.state.highlight.removeDrawing = false;
      this.removeCanvas();
      this.clearCanvas();
    },
    '$store.state.highlight.saveDrawing': function() {
      this.$store.state.highlight.removeDrawing = false;
      this.saveCanvas();
      // this.removeCanvas();
      // this.clearCanvas();
    },
    '$store.state.highlight.highlight': function () {
      // console.log("watch", '$store.state.highlight.highlight', this.$store.state.highlight.highlight)
      if (this.$store.state.highlight.highlight !== undefined) {
        let img1 = new Image();
        img1.crossOrigin = " ";
        img1.src = this.$store.state.highlight.highlight.image;
        this.img = img1;
        img1.onload = () => {
          this.setBackgroundImage(this.img);
        }
      }
    },
    '$store.state.highlight.editHighlight': function () {
      // console.log("watch", '$store.state.highlight.editHighlight', this.$store.state.highlight.editHighlight)
      this.removeCanvas();
      if (this.$store.state.highlight.editHighlight !== undefined) {
        let img1 = new Image();
        img1.crossOrigin = " ";
        img1.src = this.$store.state.highlight.editHighlight.image;
        this.img = img1;
        img1.onload = () => {
          this.setBackgroundImage(this.img);
        }
      }
    },
  },
  methods: {
    saveCanvas() {
      let c = document.getElementById("myCanvas");
      let dataUrl = c.toDataURL();
      this.$store.commit("setDrawing", dataUrl);

    },
    removeCanvas() {
      this.lines = [];
      this.historyLines = [];
    },
    setBackgroundImage(image) {
      image.crossOrigin = "anonymous";
      if (!image.src.includes("undefined")) {
        // console.log("setBackgroundImage", image);
        this.canvas.drawImage(image, 0, 0, this.$refs.canvasHolder.scrollWidth, this.$refs.canvasHolder.scrollHeight);
      }
    },
    drawLine(x1, y1, x2, y2) {
      this.drawInit();
      this.canvas.moveTo(x1, y1);
      this.canvas.lineTo(x2, y2);
      this.canvas.stroke();
      this.canvas.closePath();
    },
    canvasArrow(x2, y2) {
      this.drawInit();
      this.canvas.setLineDash([]);
      let dx = x2 - this.mouseDownX;
      let dy = y2 - this.mouseDownY;
      let angle = Math.atan2(dy, dx);
      let length = Math.sqrt(dx * dx + dy * dy);

      this.canvas.translate(this.mouseDownX, this.mouseDownY);
      this.canvas.rotate(angle);
      this.canvas.beginPath();
      this.canvas.moveTo(0, 0);
      this.canvas.lineTo(length, 0);

      //head
      this.canvas.moveTo(length - 20, -20);
      this.canvas.lineTo(length, 0);
      this.canvas.lineTo(length - 20, 20);

      this.canvas.stroke();
      this.canvas.setTransform(1, 0, 0, 1, 0, 0);
    },
    canvasRect(x2, y2) {
      this.drawInit()
      this.canvas.setLineDash([]);
      this.canvas.rect(this.mouseDownX, this.mouseDownY, x2 - this.mouseDownX - 5, y2 - this.mouseDownY);
      this.canvas.stroke();
      this.canvas.closePath();
    },
    canvasLine(x2, y2) {
      this.drawInit();
      this.canvas.setLineDash([]);
      this.canvas.lineTo(x2, y2);
      this.canvas.stroke();
      this.canvas.closePath();
    },
    canvasDottedLine(x2, y2) {
      this.drawInit();
      this.canvas.setLineDash([10, 15]);
      this.canvas.lineTo(x2, y2);
      this.canvas.stroke();
      this.canvas.closePath();
    },

    drawInit() {
      this.clearCanvas();
      this.canvas.beginPath();
      this.canvas.strokeStyle = this.color;
      this.canvas.lineWidth = this.penWidth;
      this.canvas.moveTo(this.mouseDownX, this.mouseDownY);
    },

    draw(e) {
      if (this.isDrawing) {
        switch (this.type) {
          case "free":
            this.drawLine(this.x, this.y, e.offsetX, e.offsetY);
            break;
          case "arrow":
            this.canvasArrow(e.offsetX, e.offsetY);
            break;
          case "line":
            this.canvasLine(e.offsetX, e.offsetY);
            break;
          case "dottedLine":
            this.canvasDottedLine(e.offsetX, e.offsetY);
            break;
          case "rect":
            this.canvasRect(e.offsetX, e.offsetY);
            break;
        }
        this.x = e.offsetX;
        this.y = e.offsetY;
      }
    },
    saveDrawing(e) {
      this.saveCanvas();
      this.lines.push({
        type: this.type,
        x1: this.mouseDownX,
        y1: this.mouseDownY,
        x2: e.offsetX,
        y2: e.offsetY,
        color: this.color,
        penWidth: this.penWidth
      });
    },
    beginDrawing(e) {
      this.x = e.offsetX;
      this.y = e.offsetY;
      this.mouseDownX = e.offsetX;
      this.mouseDownY = e.offsetY;
      this.isDrawing = true;
    },
    stopDrawing(e) {
      if (this.isDrawing) {
        this.x = 0;
        this.y = 0;
        this.isDrawing = false;
        this.saveDrawing(e);
      }
    },
    clearCanvas() {
      // console.log("clear Canvas")
      this.canvas.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
      let ctx = this.canvas;
      this.setBackgroundImage(this.img);

      this.lines.forEach((line) => {
        ctx.strokeStyle = line.color;
        ctx.fillStyle = line.color;
        ctx.lineWidth = line.penWidth;
       
        switch (line.type) {
          case "line":
            ctx.beginPath();
            ctx.setLineDash([]);
            ctx.moveTo(line.x1, line.y1);
            ctx.lineTo(line.x2, line.y2);
            ctx.stroke();
            ctx.closePath();
            break;

          case "dottedLine":
            ctx.beginPath();
            ctx.setLineDash([10, 15]);
            ctx.moveTo(line.x1, line.y1);
            ctx.lineTo(line.x2, line.y2);
            ctx.stroke();
            ctx.closePath();
            break;

          case "rect":
            ctx.beginPath();
            ctx.setLineDash([]);
            ctx.moveTo(line.x1, line.y1);
            ctx.rect(line.x1, line.y1, line.x2 - line.x1 - 5, line.y2 - line.y1);
            ctx.stroke();
            ctx.closePath();
            break;  

          case "arrow":
            ctx.translate(line.x1, line.y1);
            ctx.rotate(Math.atan2((line.y2 - line.y1), (line.x2 - line.x1)));
            ctx.beginPath();
            ctx.setLineDash([]);
            ctx.moveTo(0, 0);
            ctx.lineTo(Math.sqrt((line.x2 - line.x1) * (line.x2 - line.x1) + (line.y2 - line.y1) * (line.y2 - line.y1)), 0);
            //head
            ctx.moveTo(Math.sqrt((line.x2 - line.x1) * (line.x2 - line.x1) + (line.y2 - line.y1) * (line.y2 - line.y1)) - 20, -20);
            ctx.lineTo(Math.sqrt((line.x2 - line.x1) * (line.x2 - line.x1) + (line.y2 - line.y1) * (line.y2 - line.y1)), 0);
            ctx.lineTo(Math.sqrt((line.x2 - line.x1) * (line.x2 - line.x1) + (line.y2 - line.y1) * (line.y2 - line.y1)) - 20, 20);
            ctx.stroke();
            ctx.setTransform(1, 0, 0, 1, 0, 0);
            break;
        }
      });
    },

  }
}
</script>